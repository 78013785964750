import request from '@/utils/request'

export const getSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/host_withdraw_requests/setup'
  })
}

export const getHostWithdrawRequests = (params) => {
  return request({
    method: 'GET',
    url: '/cms/host_withdraw_requests',
    params
  })
}

export const getHostWithdrawRequestsId = (id) => {
  return request({
    method: 'GET',
    url: `/cms/host_withdraw_requests/${id}`
  })
}
