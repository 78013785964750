// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '80'
  }, {
    prop: 'host_name',
    label: '',
    Object: 'value',
    width: '180'
  }, {
    prop: 'current_balance',
    label: '',
    Object: 'value',
    width: '180'
  }, {
    prop: 'withdraw_amount',
    label: '',
    Object: 'value',
    width: '180'
  }, {
    prop: 'payment_info',
    label: '',
    Object: 'value',
    width: '220'
  }, {
    prop: 'created_at',
    label: '',
    Object: 'value',
    width: '180'
  }, {
    prop: 'request_status',
    label: '',
    Object: 'value',
    width: '180'
  }]
}
