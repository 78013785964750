import * as hostWithdrawRequestsApi from '@/services/host-withdraw-requests.js'
import loading from '@/utils/loading.json'
import jsonData from './jsonData.js'
import allButton from '@/components/allButton'
import searchForm from '@/components/searchForm'
import createTable from '@/components/createTable'
import dialogEffect from '@/components/dialogEffect'
import { mixin } from '@/mixins/mixin'

export default {
  mixins: [mixin],
  components: {
    searchForm,
    createTable,
    allButton,
    dialogEffect
  },
  data () {
    return {
      tableDataItem: jsonData.tableDataItem,
      tabActivityIndex: 0
    }
  },
  mounted () {
    const _this = this
    _this.queryData(hostWithdrawRequestsApi.getSetup)
  },
  activated () {
    if (this.isActive) this.queryData(hostWithdrawRequestsApi.getSetup)
    this.isActive = true
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = this.$loading(loading) : this.$store.state.app.loading.close()
    },
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      hostWithdrawRequestsApi.getHostWithdrawRequests(this.searchData).then(res => {
        if (res.data) {
          this.tableData = []
          this.$nextTick(() => {
            this.tableData = res.data.objects
            this.tabData = res.data.scopes
            Object.assign(this.pageData, res.data.pagination)
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    // 点击操作按钮
    handleClick (type, data) {
      const _this = this
      if (type?.action === 'view') {
        _this.$router.push({
          name: 'buddyManagerWithdrawalApplicationDetail',
          params: {
            id: data.id.value
          }
        })
      }
    }
  }
}
